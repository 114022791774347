import setLanguage from "next-translate/setLanguage";
import { useCallback } from "react";
import { RenderLayer } from "react-laag/dist/ToggleLayer/types";

import { useTranslation } from "@translations/use-translation";
import { Icon } from "@ui/Icon";
import { TextMedium } from "@ui/Text";
import { ToggleLayerBase } from "@ui/ToogleLayerBase";

import * as S from "./styles";

const ID = "language-switcher";

const LANGUAGES = [
    {
        title: "Čeština",
        value: "cs",
        icon: "flagCZ",
    },
    {
        title: "English",
        value: "en",
        icon: "flagUK",
    },
    {
        title: "Slovensky",
        value: "sk",
        icon: "flagSK",
    },
] as const;

export function LanguageSwitcher() {
    const { t, lang } = useTranslation();

    const renderLayer: RenderLayer = useCallback(
        ({ close, isOpen, layerProps }) => {
            return isOpen ? (
                <S.LanguageSwitcherDropdown id={ID} ref={layerProps.ref} style={layerProps.style}>
                    {LANGUAGES.map((item) => (
                        <S.LanguageSwitcherItem
                            key={`languageSwitcher-item-${item.value}`}
                            onClick={() => {
                                setLanguage(item.value);
                                close();
                            }}
                        >
                            <Icon name={item.icon} size={32} />
                            <TextMedium color="textDark" textFontWeight="medium">
                                {item.title}
                            </TextMedium>
                            {lang === item.value && <Icon name="check" size={16} color="textMuted" className="check" />}
                        </S.LanguageSwitcherItem>
                    ))}
                </S.LanguageSwitcherDropdown>
            ) : null;
        },
        [lang],
    );

    return (
        <S.LanguageSwitcherWrapper alignItems="center" boxWidth="auto" flexShrink="0">
            <ToggleLayerBase
                renderLayer={renderLayer}
                triggerComponent={
                    <S.LanguageSwitcherTrigger aria-haspopup aria-controls={ID}>
                        <Icon
                            name={LANGUAGES.find((item) => item.value === lang)?.icon || "flagCZ"}
                            size={32}
                            iconWidth={28}
                            iconHeight={28}
                        />
                        <Icon name="triangleDown" size={24} color="textMuted" />
                    </S.LanguageSwitcherTrigger>
                }
                title={t("ui-header-profile:open-menu")}
                titleOpen={t("ui-header-profile:close-menu")}
            />
        </S.LanguageSwitcherWrapper>
    );
}
